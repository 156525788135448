// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-pages-blog-index-js": () => import("../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-templates-post-js": () => import("../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-list-js": () => import("../src/templates/list.js" /* webpackChunkName: "component---src-templates-list-js" */),
  "component---src-templates-category-list-js": () => import("../src/templates/categoryList.js" /* webpackChunkName: "component---src-templates-category-list-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-concat-js": () => import("../src/pages/concat.js" /* webpackChunkName: "component---src-pages-concat-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ringindex-js": () => import("../src/pages/ringindex.js" /* webpackChunkName: "component---src-pages-ringindex-js" */),
  "component---src-pages-shopping-cart-js": () => import("../src/pages/shopping/cart.js" /* webpackChunkName: "component---src-pages-shopping-cart-js" */),
  "component---src-pages-shopping-order-js": () => import("../src/pages/shopping/order.js" /* webpackChunkName: "component---src-pages-shopping-order-js" */),
  "component---src-pages-shopping-order-details-js": () => import("../src/pages/shopping/orderDetails.js" /* webpackChunkName: "component---src-pages-shopping-order-details-js" */),
  "component---src-pages-shopping-payment-js": () => import("../src/pages/shopping/payment.js" /* webpackChunkName: "component---src-pages-shopping-payment-js" */),
  "component---src-pages-shopping-progress-js": () => import("../src/pages/shopping/progress.js" /* webpackChunkName: "component---src-pages-shopping-progress-js" */)
}

